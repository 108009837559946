import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import Modal from "react-modal";

Modal.setAppElement("#root");

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}