import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import heroImage from "images/hero.jpg";
import aboutImage from "images/about.jpg";
import extraImage from "images/extra.jpg";
import DownloadApp from "components/cta/DownloadApp";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <div id="menu">
      <AnimationRevealPage>
        <Hero
          heading={<>Favoriete Take-away <HighlightedText>Italiaanse</HighlightedText> gerechten</>}
          description="Pasta Maria is een Take-away zaak in Brugge met traditionele Italiaanse gerechten in petto! Bekijk onze menu."
          imageSrc={heroImage}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          primaryButtonText="Bekijk menu"
        />
        <MainFeature
          subheading={<Subheading>Specialiserend sinds 2013</Subheading>}
          heading={
            <>
              Sinds 2006 een gevestigde
              <wbr /> <HighlightedText>waarde</HighlightedText>
            </>
          }
          description={
            <Description>
              Pasta Maria uit Brugge serveert traditionele Italiaanse gerechten met als specialiteit onze Take Away cornetto's. Pasta gemaakt met passie en smakelijk!
            </Description>
          }
          buttonRounded={false}
          textOnLeft={false}
          primaryButtonText="Latest Offers"
          imageSrc={aboutImage}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        />
        {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
        <TabGrid
          heading={
            <>
              Ziehier onze <HighlightedText>menu.</HighlightedText>
            </>
          }
        />
        <MainFeature2
          subheading={<Subheading>Extra's</Subheading>}
          heading={<>Italiaanse <HighlightedText>Specialiteiten</HighlightedText></>}
          statistics={[
            {
              key: "Orders",
              value: "94000+",
            },
            {
              key: "Customers",
              value: "11000+"
            },
            {
              key: "Chefs",
              value: "1500+"
            }
          ]}
          primaryButtonText="Order Now"
          primaryButtonUrl="https://order.now.com"
          imageInsideDiv={false}
          imageSrc={extraImage}
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />
        {/* <Testimonial
          subheading=""
          heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
        /> */}
        <DownloadApp />
        <Footer />
      </AnimationRevealPage>
    </div>
  );
}
