import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as MailIcon } from "images/email-newsletter-icon.svg";

const Container = tw.div`relative text-gray-700 -mb-8 -mx-8 px-8 py-4 lg:py-10`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center lg:items-end justify-between`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const Informational = tw.ul`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <ThreeColRow>
          <SocialLinksContainer>
            <SocialLink href="mailto:dille.johan@telenet.be" target="_blank" rel="noreferrer">
              <MailIcon />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/pastamaria" rel="noreferrer" target="_blank">
              <FacebookIcon />
            </SocialLink>
            {/* <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
          </SocialLinksContainer>
          <Informational>
            <li>Sint-Salvatorskerkhof 13, 8000 Brugge</li>
            <li>Tel. 0476 80 76 13</li>
            <li>BE0839718409</li>
            <li>Openingstijden: ma-di-wo-vr en zaterdag van 11.00 tot 18.30</li>
          </Informational>
          <CopywrightNotice>&copy; {new Date().getFullYear()} Ontwikkeld door <a href="https://webdevamin.com/nl" target="_blank" rel="noreferrer">Webdevamin</a></CopywrightNotice>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
